export default {
  name: {
    type_id: "類型",
    date: "開始時間",
    end_date: "結束時間",
  },
  type: {
    1: "一般",
    2: "會議",
  },
};
