export default {
  name: {
    Calendar: "日程表",
    DashBoard: "儀錶盤",
    Auth: "權限",
    Rule: "規則列表",
    Group: "用戶組列表",
    Department: "部門列表",
    Nav: "菜單列表",
    NavMenu: "菜單列表",
    Member: "成員列表",
    UploadManage: "上傳管理",
    PhoneCountry: "國際電話區號",
    Carousel: "輪播圖",
    Users: "用戶列表",
    Setting: "設置",
    EngineeringManage: "工程管理",
    EngineeringDetailed: "工程詳情",
    QuotationManage: "報價管理",
    DeliveryManage: "送貨管理",
    ProcurementManage: "採購管理",
    CustomerManage: "客戶管理",
    CustomerContact: "客戶聯絡人",
    Customer: "客戶列表",
    SupplierManage: "供應商管理",
    SupplierContact: "供應商聯絡人",
    Supplier: "供應商列表",
    ProductManage: "產品管理",
    Product: "產品列表",
    ProductClassify: "產品分類",
    ReportManage: "證書/測試報告管理",
    QualityManage: "品質管理",
    InventoryManage: "庫存管理",
    ProductTechnicalSupport: "產品技術支援",
    StatisticalReport: "統計報表",
    CompanyNotice: "公司通告",
    InternalManage: "內部管理",
    AccountingManage: "会计管理",
    About: "關於我們",
    System: "系統設置",
    UnitManage: "單位管理",
    PaymentMethod: "付款方式",
    Currency: "貨幣管理",
    ReceiptManage: "收據管理",
    InvoiceManage: "發票管理",
    Diagram: "交客/生產圖",
    PaymentGoods: "貨款記錄",
    Quality: "品質問題",
    Material: "五金及物料收發",
    Purchase: "生產及採購記錄",
    OrderNote: "落單記錄",
    FireProof: "防火證書",
    PurchaseOrderManage: "採購訂單",
  },
  table: {
    order_id: "排序",
    url: "Url",
  },
  field: {
    pid: "PID",
    order_id: "排序",
    name: "名稱",
    url: "Url",
    status: "狀態",
    icon: "圖標",
  },
};
