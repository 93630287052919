import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
// import store from './store'
import i18n from "@/i18n"
import { createPinia } from 'pinia'
// 引入axios
import axios from 'axios'
import { Modal } from "ant-design-vue";

// 服务端地址   /api
// axios.defaults.baseURL = "http://192.168.3.31:8081/api"
axios.defaults.baseURL = "https://kofgym.ectg.net/api"
// axios.defaults.baseURL = "http://kofgymsys.ectg.net/api" 
// 独立API  
const app = createApp(App)
app.use(createPinia()).use(router).use(i18n).mount('#app')
// 全局提供属性
app.provide("axios", axios)
app.provide("server_url", axios.defaults.baseURL)
let vistoken = localStorage.getItem('vis-token') || '';
if (vistoken == '' || vistoken == null) {
    vistoken = Math.round(Math.random() * 100000000) + "";
    localStorage.setItem('vis-token', vistoken);
}
// createApp(App).use(i118n).use(store).use(router).mount('#app')
// axios拦截器
axios.interceptors.request.use(
    (config) => {
        if (config && config?.headers) {
            const token = localStorage.getItem('ocrs-token') || '';
            config.headers.token = token.replaceAll("\"", "");
        }
        config.headers.vistoken = vistoken;

        return config
    })
// 响应拦截器
axios.interceptors.response.use(
    function (response) {
        // 对响应数据进行操作 
        if (response) {
            // console.log('ifresponse', response);
            switch (response.data.code) {
                case 401:
                    console.log('这里没有提示登录');
                    router.replace({
                        path: 'login',
                    })
            }
        }
        return response;
    },
    // error方法是网络请求中断才会在error方法里面 
    function (error) {
        // console.log('我進來了', error);
        if (error) {
            console.log('判斷error', error);
            if (error.response.status == 401 || error.response.data.code == 401) {
                // alert('請先登錄')
                // console.log('error这里没有提示登录');
                // router.replace({
                //     path: 'login',
                // })
                Modal.info({
                    title: () => "請先登錄",
                    onOk() {
                        router.push("/login");
                    },
                });
            }
        }
        return Promise.reject(error.response.data)
    }
);
router.beforeEach((to, from, next) => {
    // chrome
    document.body.scrollTop = 0
    // firefox
    document.documentElement.scrollTop = 0
    // safari
    window.pageYOffset = 0
    next()
})

// router.afterEach((to, from, next) => {
//     window.scrollTo(0, 0);
// })
        // if (error.response) {
        //     switch (error.response.code) {
        //         case 401:
        //             router.push(`/`);

        //             // 返回 401 清除 token 信息并跳转到登录页面
        //             router.replace({
        //                 path: 'login',

        //             })
        //     }
        // }
