export default {
    information: {
        full_name: "Full Name",
        nick_name: "Nick Name",
        gender: "Gender",
        age: "Age",
        contact_number: "Contact Number",
        goal: "Goal & Special Request",
        preferred_trial_time: "Preferred Trial Time",
        morning: "Morning（09：00 - 11：00）",
        afternoon: "Afternoon（12：00 - 18：00）",
        evening: "Evening（18：00 - 22：00）",
        preferred_trial_date: "Preferred Trial Date",
        weekdays: "Weekdays",
        weekend: "Weekend",
        send_whatsApp: 'Send WhatsApp'
    },
};
