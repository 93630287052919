export default {
  name: {
    upload: "Upload",
  },
  table: {
    file_name: "File Name",
    file_md5: "File Md5",
    file_type: "File Type",
  },
  status: {
    0: "Unknown",
    1: "Private",
    2: "Public",
    3: "Delete",
  },
};
