import * as publicCommon from "./zhTw/public";
import nav from "./zhTw/nav";
import login from "./zhTw/login";
import member from "./zhTw/member";
import product from "./zhTw/product";
import payment_goods from "./zhTw/payment_goods";
import material from "./zhTw/material";
import purchase from "./zhTw/purchase";
import purchase_order from "./zhTw/purchase_order";
import calendar from "./zhTw/calendar";
import uploads from "./zhTw/uploads";
import language from "./zhTw/language";
import coach_details from "./zhTw/coach_details";
import whatsapp from "./zhTw/whatsapp_information";

export default {
    public: publicCommon.default,
    nav,
    login,
    member,
    product,
    payment_goods,
    material,
    purchase,
    purchase_order,
    calendar,
    uploads,
    language,
    coach_details,
    whatsapp,
};
