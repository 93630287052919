export default {
    information: {
        full_name: "全名：",
        nick_name: "別名：",
        gender: "性別：",
        age: "年齡：",
        contact_number: "電話號碼：",
        goal: "目标和特殊请求",
        preferred_trial_time: "試堂時間：",
        morning: "早晨（0900 - 1100）",
        afternoon: "中午（1200 - 1800）",
        evening: "夜晚（1800 - 2200）",
        preferred_trial_date: "試堂日期：",
        weekdays: "平日",
        weekend: "週末",
        send_whatsApp: '發送 WhatsApp'
    },
};
