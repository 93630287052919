import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/groupsessionbooking',
    name: 'groupsessionbooking',
    component: () => import('../views/GroupSessionBooking.vue')
  },
  {
    path: '/shop',
    name: 'rebelshop',
    component: () => import('../views/shop/RebelShop.vue')
  },
  {
    path: '/details',
    name: 'details',
    component: () => import('../views/shop/Details.vue')
  },

  {
    path: '/our_portfolio',
    name: 'our_portfolio',
    component: () => import('../views/portfolio/OurPortfolio.vue'),
    children: [
      {
        path: '/our_portfolio/our_portfolio_video',
        name: 'our_portfolio_video',
        component: () => import('../views/portfolio/OurPortfolioVideo.vue')
      },
      {
        path: '/our_portfolio/our_portfolio_articles',
        name: 'our_portfolio_articles',
        component: () => import('../views/portfolio/OurPortfolioArticles.vue')
      },


    ]
  },
  {
    path: '/our_portfolio/our_portfolio_details',
    name: 'our_portfolio_details',
    component: () => import('../views/portfolio/OurPortfolioDetails.vue')
  },
  {
    path: '/trainingsession',
    name: 'trainingsession',
    component: () => import('../views/coach/TrainingSession.vue')
  },
  {
    path: '/contact_us',
    name: 'ContactUS',
    component: () => import('../views/ContactUS.vue')
  },
  {
    path: '/our_coach',
    name: 'OurCoach',
    component: () => import('../views/OurCoach.vue')
  },
  // {
  //   path: '/coach_details',
  //   name: 'CoachDetails',
  //   component: () => import('../views/coach/coachDetails.vue')
  // },
  {
    path: '/training',
    name: 'Training',
    component: () => import('../views/coach/Training.vue')
  },


  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login/Login.vue'),
    children: [{
      path: '/login/changepassword',
      component: () => import('../views/login/changePassword.vue')
    },
    ]
  },

  {
    path: '/register',
    name: 'register',
    component: () => import('../views/login/register.vue'),
  },
  {
    path: '/password_find',
    name: 'PasswordFind',
    component: () => import('../views/login/PasswordFind.vue'),
  },
  {
    path: '/personalcenter',
    name: 'personalcenter',
    component: () => import('../views/login/PersonalCenter.vue'),
  },
  {
    path: '/disclaimers',
    name: 'disclaimers',
    component: () => import('../components/Disclaimers.vue'),
  },

  // 订单详情
  {
    path: '/settlement',
    name: 'settlement',
    component: () => import('../views/shop/Settlement.vue')
  },
  // 课程表 
  {
    path: '/questionnaireEntry',
    name: 'questionnaireEntry',
    component: () => import('../views/questionnaire/questionnaireEntry.vue')
  },
  {
    path: '/questionnaire',
    name: 'questionnaire',
    component: () => import('../views/questionnaire/questionnaire.vue')
  },
  {
    path: '/questionnaireCoursePurchase',
    name: 'questionnairecoursepurchase',
    component: () => import('../views/questionnaire/questionnaireCoursePurchase.vue')
  },
  {
    path: '/questionnaireproductpurchase',
    name: 'questionnaireProductPurchase',
    component: () => import('../views/questionnaire/questionnaireProductPurchase.vue')
  },
  {
    path: '/precisesystem',
    name: 'PreciseSystem',
    component: () => import('../views/questionnaire/PreciseSystem.vue')
  },
  {
    path: '/paidmember',
    name: 'PaidMember',
    component: () => import('../views/questionnaire/PaidMember.vue')
  },
  {
    path: '/animationanswers',
    name: 'animationAnswers',
    component: () => import('../views/questionnaire/animationAnswers.vue')
  },
  {
    path: '/detall',
    name: 'detall',
    component: () => import('../components/Detall.vue')
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})
// router.beforeEach((to, from, next) => {
//   const token = cookies.get('token')
//   if (to.meta.auth) {
//     if (!token) {
//       return router.replace({
//         name: 'login'
//       })
//     }
//     next()
//   } else {
//     next()
//   }
// })
// router.beforeEach((to, from, next) => {

//   const token = localStorage.getItem("ocrs-token")
//   if (token) {
//     next()
//   } else {
//     if (to.path == '/settlement') {
//       next('/login')
//     } else {
//       next()
//     }
//   }
// })
export default router 