export default {
    content: {
        class: "全身塑形",
        location: "定位",
        when: "什麼時候",
        time: "時間",
        status: "狀態",
        view_all_sessions: "查看所有會話",
        buy_sessions: "購買會話",
        book_a_session: "預訂會話",
        visit_propile: "準備訪問",
    },

};
