export default {
  name: {
    Calendar: "Calendar",
    DashBoard: "DashBoard",
    Auth: "Auth",
    Rule: "Rule List",
    Group: "Group List",
    Department: "Department List",
    Nav: "Nav List",
    NavMenu: "Nav List",
    Member: "Member List",
    UploadManage: "Upload Manage",
    PhoneCountry: "Phone Country",
    Users: "Users",
    Setting: "Setting",
    EngineeringManage: "Engineering Manage",
    EngineeringDetailed: "Engineering Detailed",
    QuotationManage: "Quotation Manage",
    DeliveryManage: "Delivery Manage",
    ProcurementManage: "Procurement Manage",
    CustomerManage: "Customer Manage",
    CustomerContact: "Customer Contact",
    Customer: "Customer List",
    SupplierManage: "Supplier Manage",
    SupplierContact: "Supplier Contact",
    Supplier: "Supplier List",
    ProductManage: "Product Manage",
    Product: "Product List",
    ProductClassify: "Product Classify",
    ReportManage: "Report Manage",
    QualityManage: "Quality Manage",
    InventoryManage: "Inventory Manage",
    ProductTechnicalSupport: "Product Technical Support",
    StatisticalReport: "Statistical Report",
    CompanyNotice: "Company Notice",
    InternalManage: "Internal Manage",
    AccountingManage: "Accounting Manage",
    About: "About",
    System: "System",
    UnitManage: "Unit Manage",
    PaymentMethod: "PaymentMethod",
    Currency: "Currency",
    ReceiptManage: "Receipt Manage",
    InvoiceManage: "Invoice Manage",
    Diagram: "Diagram",
    PaymentGoods: "Payment Goods",
    Quality: "Quality",
    Material: "Hardware/Material",
    Purchase: "Produce/Purchase",
    OrderNote: "Order Note",
    FireProof: "Fire Proof",
    PurchaseOrderManage: "Purchase Order",
  },
  table: {
    order_id: "Order ID",
    url: "Url",
  },
  field: {
    pid: "PID",
    order_id: "Order ID",
    name: "Name",
    url: "Url",
    status: "State",
    icon: "Icon",
  },
};
