
import { createI18n } from "vue-i18n"
import zhTW from "@/i18n/zhTW"
import enUS from "@/i18n/enUS"
const i18n = createI18n({
    locale: "enUS",
    fallbackLocale: "enUS",
    messages: {
        enUS,
        zhTW,
    }
})
export default i18n 