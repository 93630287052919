export default {
  name: {
    book_now: "立即預定",

    welcome: "欢迎",
    today: "今日",
    close: "關閉",
    status: "狀態",
    hello: "Hello",
    login: "登入",
    logout: "登出",
    username: "用戶名",
    email: "E-mail",
    password: "密碼",
    reset_password: "重置密碼",
    captcha: "驗證碼",
    more: "更多",
    add: "添加",
    edit: "編輯",
    del: "刪除",
    yes: "是",
    no: "否",
    empty: "空",
    are_you_sure_delete: "確定刪除該信息嗎",
    action_cannot_resumed: "該操作無法恢復",
    search: "搜索",
    reset: "重置",
    spread: "展開",
    retract: "收縮",
    show: "展示",
    no_page: "無頁面",
    personal: "個人信息",
    enclosure: "附件",
    logo: "Logo",
    top_logo: "頂部Logo",
    bottom_logo: "底部Logo",
    name: "名稱",
    submit: "提交",
    phone: "電話",
    fax: "FAX",
    address: "地址",
    office_message: "辦公時間信息",
    remark: "備註",
    online_date: "起止時間",
    image: "圖像",
    name_cn: "中文名",
    name_en: "英文名",
    customer: "客戶",
    supplier: "供應商",
    contact: "聯絡人",
    classify: "分類",
    unit: "單位",
    copy_right: "Copy Right",
    return: "返回",
    product: "產品",
    download: "下載",
  },
  status: {
    0: "未知",
    1: "正常",
    2: "禁用",
  },
  issue_status: {
    0: "未知",
    1: "即將上市",
    2: "檔案",
  },
  sex: {
    1: "男",
    2: "女",
    3: "未知",
  },
  placeholder: {
    please_select: "請選擇",
    please_select_customer: "請選擇",
  },
  table: {
    id: "ID",
    title: "Title",
    date: "日期",
    name: "名稱",
    name_cn: "中文名",
    name_en: "英文名",
    status: "狀態",
    action: "操作",
    content: "內容",
    created_at: "創建時間",
    image: "圖片",
    email: "E-mail",
    add_time: "創建時間",
    update_time: "更新時間",
    order_id: "排序",
    sex: "性別",
    last_ip: "最後登錄IP",
    phone_country_code: "電話國家代碼",
    fax_country_code: "傳真國家代碼",
    phone: "電話",
    fax: "傳真",
    customer_name: "客戶名稱",
    classify: "分類",
    unit: "單位",
    stock: "庫存",
    department: "部門",
  },
  field: {
    name: "名稱",
    follow_name: "跟進者名稱",
    name_cn: "中文名",
    name_en: "英文名",
    content: "內容",
    url: "Url",
    customer: "客戶",
    contact: "聯系人",
    phone_country_code: "電話國際區號",
    fax_country_code: "傳真國際區號",
    phone: "電話",
    email: "E-mail",
    order_id: "排序",
    portrait: "頭像",
    status: "狀態",
    image: "圖像",
    sex: "性別",
    title: "標題",
    date: "日期",
    issue_status: "檔案類型",
    fax: "傳真",
    address: "地址",
    address_cn: "地址(中文)",
    address_en: "地址(英文)",
    type: "類型",
    department: "部門",
    sign_photo: "電腦簽名",
  },
  validate: {
    required: {
      id: "Lack ID",
      username: "請輸入用戶名",
      password: "請輸入密碼",
      repassword: "請重復密碼",
      name: "請輸入名稱",
      name_language: "請輸入中文名或英文名",
      address_language: "請輸入地址(中文)或地址(英文)",
      phone: "請輸入電話號碼",
      fax: "請輸入傳真號碼",
      email: "請輸入E-mail",
      portrait: "請上傳頭像",
      file: "請上傳文件",
      online_date: "請輸入起止時間",
      phone_country_code: "請選擇電話國家代碼",
      status: "請選擇狀態",
      content: "請輸入內容",
    },
    tips: {
      inconsistent_password: "密碼不一致",
      length_should: "長度應爲{min}至{max}",
    },
  },
};
