export default {
  name: {
    reset_password: "重置密碼",
    search_user_name: "搜索用戶名",
  },
  table: {
    username: "用戶名",
    phone: "區號及電話",
    last_ip: "最後登錄IP",
  },
  field: {
    username: "用戶名",
    password: "密碼",
    repassword: "重複密碼",
    sex: "性別",
    birthday: "出生年月日",
    salary: "員工薪金",
  },
  validate: {
    required: {},
  },
};
