export default {
    content: {
        class: "RESHAPE FULL BODY",
        location: "Location",
        when: "When",
        time: "Time",
        status: "Status",
        view_all_sessions: "view all sessions",
        buy_sessions: "buy sessions",
        book_a_session: "BOOK A SESSION",
        visit_propile: "VISIT PROPILE",
    },

};
