export default {
  name: {
    book_now: "BOOK NOW",

    welcome: "Welcome",
    today: "Today",
    close: "Close",
    status: "State",
    hello: "Hello",
    login: "Login",
    logout: "Logout",
    username: "Username",
    email: "E-mail",
    password: "Password",
    reset_password: "Reset Password",
    captcha: "Captcha",
    more: "More",
    add: "Add",
    edit: "Edit",
    del: "Delete",
    yes: "Yes",
    no: "No",
    empty: "Empty",
    are_you_sure_delete: "Are you Sure Want To Delete This Item",
    action_cannot_resumed: "This Action Cannot Be Resumed",
    search: "Search",
    reset: "Reset",
    spread: "Spread",
    retract: "Retract",
    show: "Show",
    no_page: "No Page",
    personal: "Personal",
    enclosure: "Enclosure",
    logo: "Logo",
    top_logo: "Top Logo",
    bottom_logo: "Bottom Logo",
    name: "Name",
    submit: "Submit",
    phone: "Phone",
    fax: "FAX",
    address: "Address",
    office_message: "Office Message",
    remark: "Remark",
    online_date: "Online Time",
    image: "Image",
    name_cn: "Chinese Name",
    name_en: "English Name",
    customer: "Customer",
    supplier: "Supplier",
    contact: "Contact",
    classify: "Classify",
    unit: "Unit",
    copy_right: "Copy Right",
    return: "Return",
    product: "Product",
    download: "Download",
  },
  status: {
    0: "Unknown",
    1: "Normal",
    2: "Disable",
  },
  issue_status: {
    0: "Unknown",
    1: "Be Listed",
    2: "Document",
  },
  sex: {
    1: "Man",
    2: "Woman",
    3: "Unknown",
  },
  placeholder: {
    please_select: "Please Select",
    please_select_customer: "Please Select Customer",
  },
  table: {
    id: "ID",
    date: "Date",
    title: "Title",
    name: "Name",
    name_cn: "Chinese Name",
    name_en: "English Name",
    status: "State",
    action: "Action",
    content: "Content",
    created_at: "Create Time",
    image: "Image",
    email: "E-mail",
    add_time: "Creater Time",
    update_time: "Update Time",
    last_ip: "Last Ip",
    phone_country_code: "Phone Country Code",
    fix_country_code: "Fix Country Code",
    phone: "Phone",
    fax: "Fax",
    customer_name: "Customer Name",
    classify: "Classify",
    unit: "Unit",
    stock: "Stock",
    department: "Department",
  },
  field: {
    name: "Name",
    follow_name: "Follow Name",
    name_cn: "Chinese Name",
    name_en: "English Name",
    content: "Content",
    url: "Url",
    customer: "Customer",
    contact: "Contact",
    phone_country_code: "Phone Country Code",
    fix_country_code: "Fix Country Code",
    phone: "Phone",
    email: "E-mail",
    order_id: "Order",
    portrait: "Portrait",
    status: "State",
    image: "Image",
    sex: "Sex",
    title: "Title",
    date: "Date",
    issue_status: "Issue Type",
    fax: "Fax",
    address: "Adress",
    address_cn: "Address(Chinese)",
    address_en: "Address(English)",
    type: "Type",
    department: "Department",
    sign_photo: "Sign Photo",
  },
  validate: {
    required: {
      id: "Lack ID",
      username: "Please Entry User Name",
      password: "Please Entry Password",
      repassword: "Please Repeat password",
      name: "Please Entry Name",
      name_language: "Please Entry Chinese Name Or English Name",
      address_language: "Please Entry Address(Chinese) Or Address(English)",
      phone: "Please Entry Phone",
      fax: "Please Entry Fax",
      email: "Please Entry E-mail",
      portrait: "Please Upload Portrait",
      file: "Please Upload File",
      online_date: "Please Entry Online Date",
      phone_country_code: "Please Select Phone Country Code",
      status: "Please Select Status",
      content: "Please Entry Content",
    },
    tips: {
      inconsistent_password: "Inconsistent password",
      length_should: "Length Should Be {min} To {max}",
    },
  },
};
