export default {
  table: {
    number: "Number",
    barcode: "Barcode",
    model: "Model",
    stock: "Stock",
    remark: "Description",
  },
  field: {
    classify: "Classify",
    number: "Number",
    barcode: "Barcode",
    model: "Model",
    stock: "Stock",
    remark: "Description",
  },
  validate: {
    required: {
      number: "Please Entry Number",
      barcode: "Please Entry Barcode",
      model: "Please Entry Model",
      classify_id: "Please Select Classify",
      unit: "Please Select Unit",
    },
  },
};
