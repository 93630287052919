export default {
  name: {
    reset_password: "Reset Password",
    search_user_name: "Search User Name",
  },
  table: {
    username: "UserName",
    phone: "Phone",
    last_ip: "Last IP",
  },
  field: {
    username: "User Name",
    password: "Password",
    repassword: "Repeat password",
    sex: "Sex",
    birthday: "Birthday",
    salary: "Salary",
  },
  validate: {
    required: {},
  },
};
