import * as publicCommon from "./enUs/public";
import nav from "./enUs/nav";
import login from "./enUs/login";
import member from "./enUs/member";
import product from "./enUs/product";
import payment_goods from "./enUs/payment_goods";
import material from "./enUs/material";
import purchase from "./enUs/purchase";
import purchase_order from "./enUs/purchase_order";
import calendar from "./enUs/calendar";
import uploads from "./enUs/uploads";
import language from "./enUs/language";
import coach_details from "./enUs/coach_details";
import whatsapp from "./enUs/whatsapp_information";

export default {
    public: publicCommon.default,
    nav,
    login,
    member,
    product,
    payment_goods,
    material,
    purchase,
    purchase_order,
    calendar,
    uploads,
    language,
    coach_details,
    whatsapp,
};
