import { defineStore } from "pinia";
import axios from 'axios'
import { useRouter } from "vue-router";
import { message } from "ant-design-vue";
const router = useRouter();
export const cartStore = defineStore("cartStore", {
    state: () => {
        return {
            language: "enUS",
            logintoken: localStorage.getItem("ocrs-token") || [],
            windowarea: "signin",
            reddot: 0,
            messagesreddot: 0,
            token: "",
            loginactiveKey: 1,
            whatsapp: "",
            logininformation: {
                remember: localStorage.getItem("remember") == 'true' ? true : false || false,
                account: localStorage.getItem("account") || '',
                password: ''
            },
            pushs: [],
            oursinformation: [],
            linkinformation: [],
        };
    },
    actions: {
        outs() {
            axios.post("/auth/logout").then(() => {
                localStorage.removeItem("user");
                localStorage.removeItem("ocrs-token")
                this.windowarea = 'signin';
            });
        },
        // 加两个小红点
        get_cart() {
            axios.get("/cart/count").then((response) => {
                this.reddot = response.data.data.count;
                this.messagesreddot = response.data.data.messages;
                this.whatsapp = response.data.data.whatsapp;
            });
            axios.get("/page/contact2").then((response) => {
                this.oursinformation = response.data.data;
                // console.log('oursinformation', this.oursinformation);

            });
            axios.get("/page/index?pid=49").then((response) => {
                this.linkinformation = response.data.data;
            });
        },
        get_pushs() {
            axios.get("/message/pushs?page=1").then((response) => {
                this.pushs = response.data.data.data;
            });
        },
        language_change(state: any): void {
            // switch (store.getters.language) {
            //     case "enUS":
            //       i18n.global.locale = "enUS";
            //       store.commit("language", "enUS");
            //       break;
            //     default:
            //       i18n.global.locale = "zhTW";
            //       store.commit("language", "zhTW");
            //       break;
            //   }
        },
    }
});
