export default {
  name: {
    type_id: "Type",
    date: "Start Date",
    end_date: "End Date",
  },
  type: {
    1: "Normal",
    2: "Meeting",
  },
};
