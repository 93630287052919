export default {
  table: {
    number: "產品編號",
    barcode: "Barcode",
    model: "產品型號",
    stock: "庫存",
    remark: "產品描述",
  },
  field: {
    classify: "產品分類",
    number: "產品編號",
    barcode: "Barcode",
    model: "產品型號",
    stock: "庫存",
    remark: "產品描述",
  },
  validate: {
    required: {
      number: "請輸入產品編號",
      barcode: "請輸入Barcode",
      model: "請輸入產品型號",
      classify_id: "請選擇分類",
      unit: "請選擇單位",
    },
  },
};
